
import { Modal } from "bootstrap";
import { defineComponent, ref, inject } from "vue";
import { Input, Select } from "@/components/input-elements";
import { Apollo, Notify } from "@/core/services";
import { EditTaxForm } from "../interfaces";
import { UPDATE_TAX } from "../graphql/Mutations";
import { GET_LOCALES } from "@/modules/common/locales/graphql/Queries";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "edit tax",
	components: {
		Input,
		Select,
		InnerLoader,
	},

	setup() {
		const modal: any = ref();
		const loading = ref(false);
		const locales = ref([]) as Record<any, any>;
		const locale = ref("da");
		const emitter: any = inject("emitter");
    const current_page = ref(1);
    const i18n = useI18n();
		const editTaxForm = ref<null | HTMLFormElement>(null);

		// Given   Edit Tax Form Interface
		const editTaxData = ref<EditTaxForm>({
			id: null,
			name: "",
			rate_type: "",
			rate: null,
		});

		const rate_type = ref([
			{ label: "message.AMOUNT", value: "Amount" },
			{ label: "message.PERCENTAGE", value: "Percentage" },
		]);

		// Store Name in Danish and English
		const jsonNameFields = ref({}) as Record<any, any>;

		const loadLang = () => {
			const locales_data = Apollo.readQuery({
				query: GET_LOCALES,
			});
			locales_data.locales.forEach((element) => {
				locales.value.push({
					label: element.locale.charAt(0).toUpperCase() + element.locale.slice(1),
					value: element.locale,
				});

				jsonNameFields.value[element.locale] = { name: "" };
			});
		};

		loadLang();

		// On change input value
		const changeLang = (e: string) => {
			editTaxData.value.name = jsonNameFields.value[e].name;
		};

		// update on change value input
		const updateValue = (e: string) => {
			jsonNameFields.value[locale.value].name = e;
		};

		// Submit Handler Request
		const submitHandlerForm = async () => {
			editTaxForm.value?.validate(async (valid: boolean) => {
				if (valid) {
					for (const i in jsonNameFields.value) {
						if (jsonNameFields.value[i].name == "") {
							jsonNameFields.value[i].name = editTaxData.value.name;
						}
					}
					const formData = {
						id: editTaxData.value.id,
						name: JSON.stringify(jsonNameFields.value),
						rate_type: editTaxData.value.rate_type,
						rate: editTaxData.value.rate,
					};

					loading.value = true;
					await Apollo.mutate({
						mutation: UPDATE_TAX,
						variables: { input: formData },
						update: (store, { data: { update_tax } }) => {
							modal.value.hide();
							loading.value = false;
							Notify.success(i18n.t("message.RECORD_UPDATED_SUCCESSFULLY"));
						},
					}).catch(() => {
						loading.value = false;
					});
				}
			});
		};

		// Reset Form Data On Model Popup
		const resetForm = () => {
			emitter.emit("clearInput");
      current_page.value = 1;
      editTaxForm.value?.resetFields();
			for (const i of Object.getOwnPropertyNames(jsonNameFields.value)) {
				jsonNameFields.value[i].name = "";
			}
		};

		// Emitter To Open Model
		emitter.on("editTaxAction", (tax: Record<any, any>) => {
			resetForm();
      current_page.value = tax.current_page
      locale.value = "da";
			editTaxData.value.id = tax.id;
			const json_decode_name = JSON.parse(tax.name);
			for (const i in json_decode_name) {
				i == "da" ? (editTaxData.value.name = json_decode_name[i].name) : "";
				jsonNameFields.value[i].name = json_decode_name[i].name;
			}
			editTaxData.value.rate = tax.rate;
			editTaxData.value.rate_type = tax.rate_type;
			modal.value = new Modal(document.getElementById("modal_edit_tax"));
			modal.value.show();
		});

		return {
			loading,
			editTaxData,
			editTaxForm,
			rate_type,
			locales,
			locale,
			changeLang,
			updateValue,
			submitHandlerForm,
		};
	},
});
