
import { Modal } from "bootstrap";
import { defineComponent, ref, inject } from "vue";
import { Input, Select } from "@/components/input-elements";
import { Apollo, Notify } from "@/core/services";
import { AddTaxForm } from "../interfaces/index";
import { GET_TAX } from "../graphql/Queries";
import { CREATE_TAX } from "../graphql/Mutations";
import { GET_LOCALES } from "@/modules/common/locales/graphql/Queries";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "add new tax",
	components: {
		Input,
		Select,
		InnerLoader,
	},

	setup() {
		const modal: any = ref();
		const loading = ref(false);
		const locales = ref([]) as Record<any, any>;
		const locale = ref("da");
		const emitter: any = inject("emitter");
		const i18n = useI18n();
		const taxForm = ref<null | HTMLFormElement>(null);

		// Given Add Tax Form Interface
		const taxData = ref<AddTaxForm>({
			name: "",
			rate_type: "",
			rate: null,
		});

		const rate_type = ref([
			{ label: "message.AMOUNT", value: "Amount" },
			{ label: "message.PERCENTAGE", value: "Percentage" },
		]);

		// Store Name in Danish and English
		const jsonNameFields = ref({}) as Record<any, any>;

		const loadLang = () => {
			const locales_data = Apollo.readQuery({
				query: GET_LOCALES,
			});
			locales_data.locales.forEach((element) => {
				locales.value.push({
					label: element.locale.charAt(0).toUpperCase() + element.locale.slice(1),
					value: element.locale,
				});

				jsonNameFields.value[element.locale] = { name: "" };
			});
		};

		loadLang();

		// On change input value
		const changeLang = (e: string) => {
			taxData.value.name = jsonNameFields.value[e].name;
		};

		// update on change value input
		const updateValue = (e: string) => {
			jsonNameFields.value[locale.value].name = e;
		};

		// Submit Handler Request
		const submitHandlerForm = async () => {
			taxForm.value?.validate(async (valid: boolean) => {
				if (valid) {
					for (const i in jsonNameFields.value) {
						if (jsonNameFields.value[i].name == "") {
							jsonNameFields.value[i].name = taxData.value.name;
						}
					}

					const formData = {
						name: JSON.stringify(jsonNameFields.value),
						rate_type: taxData.value.rate_type,
						rate: taxData.value.rate,
					};

					loading.value = true;
					await Apollo.mutate({
						mutation: CREATE_TAX,
						variables: { input: formData },
						update: (store, { data: { create_tax } }) => {
							const pervious_record = store.readQuery({
								query: GET_TAX,
								variables: {
									page: 1,
									limit: 10,
								},
							}) as Record<any, any>;
							store.writeQuery({
								query: GET_TAX,
								variables: {
									page: 1,
									limit: 10,
								},
								data: {
									taxes: {
										...pervious_record.taxes,
										data: [create_tax, ...pervious_record.taxes.data],
									},
								},
							});
							modal.value.hide();
							loading.value = false;
							Notify.success(i18n.t("message.RECORD_ADDED_SUCCESSFULLY"));
						},
					}).catch(() => {
						loading.value = false;
					});
				}
			});
		};

		// Reset Form Data On Model Popup
		const resetForm = () => {
			emitter.emit("clearInput");
			locale.value="da";
			for (const i of Object.getOwnPropertyNames(jsonNameFields.value)) {
				jsonNameFields.value[i].name = "";
			}
			taxForm.value?.resetFields();
			taxData.value.rate = null;
		};

		// Emitter To Open Model
		emitter.on("addTaxAction", () => {
			resetForm();
			modal.value = new Modal(document.getElementById("modal_add_tax"));
			modal.value.show();
		});

		return {
			loading,
			taxData,
			taxForm,
			rate_type,
			locales,
			locale,
			changeLang,
			updateValue,
			submitHandlerForm,
		};
	},
});
