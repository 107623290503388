
import { Modal } from "bootstrap";
import { defineComponent, ref, inject, watchEffect } from "vue";
import { Input, Select } from "@/components/input-elements";
import { Apollo, Notify } from "@/core/services";
import { EditTaxRuleForm } from "../interfaces/index";
import { GET_TAX } from "../../taxes/graphql/Queries";
import { GET_COUNTRIES } from "@/modules/common/countries/graphql/Queries";
import { GET_TAX_RULE } from "../graphql/Queries";
import { UPDATE_TAX_RULE } from "../graphql/Mutations";
import { GET_LOCALES } from "@/modules/common/locales/graphql/Queries";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "edit tax rule",
	components: {
		Input,
		Select,
		InnerLoader,
	},

	setup() {
		const modal: any = ref();
		const loading = ref(false);
		const emitter: any = inject("emitter");
    const current_page = ref(1);
    const i18n = useI18n();
		const editTaxRuleForm = ref<null | HTMLFormElement>(null);
		const taxes = ref([]) as Record<any, any>;
		const countries = ref([]);
		const locales = ref([]) as Record<any, any>;
		const locale = ref("da");
		const show_tax_info = ref([]) as Record<any, any>;

		// Given Edit Tax Rule Form Interface
		const editTaxRuleData = ref<EditTaxRuleForm>({
			id: null,
			name: "",
			tax_id: null,
			countries: [],
		});

		const taxesList = ref([]);
		// Get Tax list
		const get_tax = () => {
			Apollo.watchQuery({
				query: GET_TAX,
				fetchPolicy: "network-only",
				nextFetchPolicy: "network-only",
			}).subscribe(({ data }) => {
				taxesList.value = [];
				taxesList.value = data.taxes.data;
			});
		};

		watchEffect(() => {
			taxes.value = [];
			taxesList.value.forEach((element: Record<any, any>) => {
				let defaultLocale = "" as any;
				if (JSON.parse(element.name)[`${locale.value}`] == undefined) {
					defaultLocale = "da";
				} else {
					defaultLocale = locale.value;
				}
				taxes.value.push({
					value: element.id,
					label: JSON.parse(element.name)[`${defaultLocale}`].name,
					price: element.rate,
					type: element.rate_type,
				});
			});

			const tax = taxes.value.find((element) => element.value == editTaxRuleData.value.tax_id);
			if (tax) {
				show_tax_info.value.push({
					"message.TAX_RATE_TYPE": tax.type,
					"message.TAX_RATE": tax.price,
				});
			}
		});

		// Get Countries list
		const getCountries = () => {
			const countries_data = Apollo.readQuery({
				query: GET_COUNTRIES,
			});

			countries.value = countries_data.countries.map((single: Record<any, any>) => {
				const country = {};
				country["label"] = single.name;
				country["value"] = single.id;
				return country;
			});
		};

		// Store Name in Danish and English
		const jsonNameFields = ref({}) as Record<any, any>;

		const loadLang = () => {
			const locales_data = Apollo.readQuery({
				query: GET_LOCALES,
			});
			locales_data.locales.forEach((element) => {
				locales.value.push({
					label: element.locale.charAt(0).toUpperCase() + element.locale.slice(1),
					value: element.locale,
				});
				jsonNameFields.value[element.locale] = { name: "" };
			});
		};

		loadLang();

		// Submit Handler Request
		const submitHandlerForm = async () => {
			editTaxRuleForm.value?.validate(async (valid: boolean) => {
				if (valid) {
					for (const i in jsonNameFields.value) {
						if (jsonNameFields.value[i].name == "") {
							jsonNameFields.value[i].name = editTaxRuleData.value.name;
						}
					}
					const formData = {
						id: editTaxRuleData.value.id,
						name: JSON.stringify(jsonNameFields.value),
						tax_id: editTaxRuleData.value.tax_id,
						countries: JSON.stringify(editTaxRuleData.value.countries),
					};

					loading.value = true;
					await Apollo.mutate({
						mutation: UPDATE_TAX_RULE,
						variables: { input: formData },
						update: (store, { data: { update_tax_rule } }) => {
							modal.value.hide();
							loading.value = false;
							Notify.success(`${i18n.t("message.RECORD_UPDATED_SUCCESSFULLY")}`);
						},
					}).catch((e) => {
						Notify.error(e);
						loading.value = false;
					});
				}
			});
		};

		// On change input value
		const changeLang = (e: string) => {
			editTaxRuleData.value.name = jsonNameFields.value[e].name;
		};

		// update on change value input
		const updateValue = (e: string) => {
			jsonNameFields.value[locale.value].name = e;
		};

		const changeTax = (id: number) => {
			show_tax_info.value = [];
			const tax = taxes.value.find((element) => element.value == id);
			show_tax_info.value.push({
				"message.TAX_RATE_TYPE": tax.type,
				"message.TAX_RATE": tax.price,
			});
		};

		// Reset Form Data On Model Popup
		const resetForm = () => {
			show_tax_info.value = [];
			get_tax();
			getCountries();
			emitter.emit("clearInput");
			for (const i of Object.getOwnPropertyNames(jsonNameFields.value)) {
				jsonNameFields.value[i].name = "";
			}
			editTaxRuleForm.value?.resetFields();
		};

		// Emitter To Open Model
		emitter.on("editTaxRuleAction", (tax_rule) => {
			resetForm();
			locale.value = "da";
			editTaxRuleData.value.id = tax_rule.id;
			const json_decode_name = JSON.parse(tax_rule.name);
			for (const i in json_decode_name) {
				i == "da" ? (editTaxRuleData.value.name = json_decode_name[i].name) : "";
				jsonNameFields.value[i].name = json_decode_name[i].name;
			}
			editTaxRuleData.value.tax_id = tax_rule.tax.id;
			editTaxRuleData.value.countries = JSON.parse(tax_rule.countries);
			modal.value = new Modal(document.getElementById("modal_edit_tax_rule")) as HTMLElement;
			modal.value.show();
		});

		return {
			taxes,
			loading,
			countries,
			editTaxRuleData,
			editTaxRuleForm,
			show_tax_info,
			locales,
			locale,
			changeLang,
			updateValue,
			submitHandlerForm,
			changeTax,
		};
	},
});
