import { gql } from "graphql-tag";
import { TAX_FIELDS } from "./Fragments";

export const CREATE_TAX = gql`
	mutation CreateTax($input: TaxInput!) {
		create_tax(tax: $input) {
			...TaxFields
		}
	}
	${TAX_FIELDS}
`;

export const UPDATE_TAX = gql`
	mutation UpdateTax($input: TaxInput!) {
		update_tax(tax: $input) {
			...TaxFields
		}
	}
	${TAX_FIELDS}
`;

export const DELETE_TAX = gql`
	mutation DeleteTax($id: Int!) {
		delete_tax(id: $id) {
			...TaxFields
		}
	}
	${TAX_FIELDS}
`;

export default { CREATE_TAX, UPDATE_TAX, DELETE_TAX };
