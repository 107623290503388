import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TaxesList = _resolveComponent("TaxesList")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_TaxRulesList = _resolveComponent("TaxRulesList")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_AddTax = _resolveComponent("AddTax")!
  const _component_EditTax = _resolveComponent("EditTax")!
  const _component_AddTaxRule = _resolveComponent("AddTaxRule")!
  const _component_EditTaxRule = _resolveComponent("EditTaxRule")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_tabs, {
      class: "taxes-tabs",
      "tab-position": 'top',
      modelValue: _ctx.tabsValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.tabsValue = $event))
    }, {
      default: _withCtx(() => [
        (_ctx.$can('view_taxes_list'))
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 0,
              label: _ctx.$t('message.TAXES')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_TaxesList)
              ]),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true),
        (_ctx.$can('view_tax_rules_list'))
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 1,
              label: `${_ctx.$t('message.TAX')} ${_ctx.$t('message.RULES')}`
            }, {
              default: _withCtx(() => [
                (_ctx.taxRuleTab == true)
                  ? (_openBlock(), _createBlock(_component_TaxRulesList, { key: 0 }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_AddTax),
    _createVNode(_component_EditTax),
    _createVNode(_component_AddTaxRule),
    _createVNode(_component_EditTaxRule)
  ], 64))
}