import { gql } from "graphql-tag";
import { TAX_RULE_FIELDS } from "./Fragments";

export const CREATE_TAX_RULE = gql`
	mutation CreateTaxRule($input: TaxRuleInput!) {
		create_tax_rule(tax_rule: $input) {
			...TaxRuleFields
		}
	}
	${TAX_RULE_FIELDS}
`;

export const UPDATE_TAX_RULE = gql`
	mutation UpdateTaxRule($input: TaxRuleInput!) {
		update_tax_rule(tax_rule: $input) {
			...TaxRuleFields
		}
	}
	${TAX_RULE_FIELDS}
`;

export const DELETE_TAX_RULE = gql`
	mutation DeleteTaxRule($id: Int!) {
		delete_tax_rule(id: $id) {
			...TaxRuleFields
		}
	}
	${TAX_RULE_FIELDS}
`;

export default { CREATE_TAX_RULE, UPDATE_TAX_RULE, DELETE_TAX_RULE };
