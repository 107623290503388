
import {setPageHeader,} from "@/core/helpers/toolbar";
import { defineComponent, ref, watchEffect} from 'vue'
import TaxesList from "@/modules/system/taxes/components/TaxesList.vue"
import AddTax from "@/modules/system/taxes/components/AddTax.vue"
import EditTax from "@/modules/system/taxes/components/EditTax.vue"

import AddTaxRule from "@/modules/system/tax-rules/components/AddTaxRule.vue"
import EditTaxRule from "@/modules/system/tax-rules/components/EditTaxRule.vue"
import TaxRulesList from "@/modules/system/tax-rules/components/TaxRulesList.vue"

export default defineComponent({
    components:{
        AddTax,
        EditTax,
        TaxesList,
        
        AddTaxRule,
        EditTaxRule,
        TaxRulesList
    },

    setup() {
        const tabsValue = ref(0)
        const taxRuleTab = ref(false)  

        watchEffect(() => {
            if(tabsValue.value == 0) {
                setPageHeader({
                    title: "message.TAXES",
                    actionButton: {
                        ability:'add_tax',
                        pageAction: {
                            action: "addTaxAction",
                        },
                        button: {
                            icon: "bi bi-plus",
                            openModal: true,
                        }
                    },
                    breadCrumbs: [
                        { name: "message.HOME", link: "/dashboard"},
                        { name: "message.SYSTEM", link: "#"},
                        { name: "message.SETTINGS", link: "/system/settings"},
                        { name: "message.TAXES"}
                    ]
                });
            } else  {
                taxRuleTab.value = true
                setPageHeader({
                    title: "message.TAXES message.RULES",
                    actionButton: {
                        ability:'add_tax_rule',
                        pageAction: {
                            action: "addTaxRuleAction",
                        },
                        button: {
                            icon: "bi bi-plus",
                            openModal: true,
                        }
                    },
                    breadCrumbs: [
                        { name: "message.HOME", link: "/dashboard"},
                        { name: "message.SYSTEM", link: "#"},
                        { name: "message.SETTINGS", link: "/system/settings"},
                        { name: "message.TAXES message.RULES"}
                    ]
                });
            }
        })

        return {
            tabsValue,
            taxRuleTab
        }
        
    },
})
