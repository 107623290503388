import { gql } from "graphql-tag";
import { TAX_FIELDS, PAGINATION } from "./Fragments";

export const GET_TAX = gql`
    query GetTax($page: Int, $limit: Int) {
        taxes(limit:$limit, page:$page){
            ...Pagination
            data {
                ...TaxFields
            }
        }
    }
    ${TAX_FIELDS},
    ${PAGINATION},
`;

export const SEARCH_TAX = gql`
    query SearchTax($page: Int, $limit: Int, $filter: String, $search_key: String, $locale:String, $type:String , $date_from:String , $date_to:String) {
        search_taxes(limit:$limit, page:$page, filter:$filter, search_key:$search_key, locale:$locale, type:$type , date_from:$date_from , date_to:$date_to){
            ...Pagination
            data {
                ...TaxFields
            }
        }
    }
    ${TAX_FIELDS},
    ${PAGINATION},
`;

export default { GET_TAX, SEARCH_TAX };