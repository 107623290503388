import { gql } from "graphql-tag";

export const TAX_FIELDS = gql`
  fragment TaxFields on Tax {
    id
    name
    path
    rate_type
    rate
    created_at
  }
`;

export const PAGINATION = gql`
  fragment Pagination on TaxPagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
`;

export default { TAX_FIELDS, PAGINATION };